<template>
  <div class="page">
    <!-- Loading icon and text -->
    <div v-if="loading">
      <!-- Loading icon -->
      <img src="../../../assets/Icons/Loading.gif" alt="Loading Icon" class="loading-animation" />
      <p>Stiamo controllando i tuoi canali social.</p>
    </div>
    <!-- Box with social media channels -->
    <div v-if="!showMetricsConstant && !loading && showBox">
      <h3>Conferma canali social per {{ this.sessionstore.company_name }}</h3>
      <p>Sito web: {{ this.sessionstore.website }}</p>
      <!-- Loop through filteredSocialChannels -->
      <div v-for="(channel, index) in filteredSocialChannels" :key="index" class="channel">
        <!-- SVG icon -->
        <component :is="channel.icon" />
        <p class="nome-canale">{{ channel.text }}</p>
        <!-- X icon to delete channel -->
        <XIcon class="elimina-bottone" @click="deleteChannel(index)" />
      </div>
      <!-- Confirmation message -->
      <p v-if="filteredSocialChannels.length > 0" class="sub-text">
        Abbiamo trovato questi canali social. Clicca su Conferma se sono tutti oppure eliminali o aggiungili usando la
        barra sotto.
      </p>
      <!-- Text input and dropdown -->
      <div class="add-channel">
        <input type="text" v-model="newChannelText" placeholder="Inserisci un nuovo canale" />
        <select v-model="selectedChannel">
          <option v-for="channel in availableChannels" :key="channel" :value="channel">{{ channel }}</option>
        </select>
        <!-- Plus icon to add channel -->
        <PlusIcon class="aggiungi-bottone" @click="addChannel" />
      </div>
      <ButtonTemplate :button-text="'Conferma'" @click="showMetrics" class="confirm-button" />
    </div>
    <div v-if="showMetricsConstant" class="metrics">
      <CheckMetrics :socialChannels="socialChannels" @click-metrics-confirm-button="showFollowingPage" />
    </div>

    <div v-if="ShowStorytellingPage" class="story-telling-page">
      <StoryTellingConfirm @advance-step="showNavigationMenu" />
    </div>
    <div v-if="ShowExperiencePage" class="experience-page">
      <ExperienceConfirm @advance-step="showNavigationMenu" />
    </div>
    <div v-if="ShowCommunityPage" class="community-page">
      <CommunityConfirm @advance-step="showNavigationMenu" />
    </div>
    <div v-if="ShowFinalPage" class="matrix-page">
      <FinalPage
        :ratings="[[sessionstore.storytellingScore, sessionstore.experienceScore, sessionstore.communityScore]]"
        :names="[sessionstore.company_name]">
      </FinalPage>
    </div>

    <div v-if="ShowNavigationMenu" class="steps">
      <StepIndicator :currentStep="CurrentIndex" @forward-event="showFollowingPage" @back-event="showPreviousPage" />
    </div>
  </div>

</template>

<script>
import XIcon from '../../../assets/Icons/xIcon.vue';
import PlusIcon from '../../../assets/Icons/PlusIcon.vue';
import FacebookIcon from '../../../assets/Icons/SocialMedia/Facebook.vue';
import InstagramIcon from '../../../assets/Icons/SocialMedia/Instagram.vue';
import TiktokIcon from '../../../assets/Icons/SocialMedia/Tiktok.vue';
import LinkedinIcon from '../../../assets/Icons/SocialMedia/Linkedin.vue';
import TwitterIcon from '../../../assets/Icons/SocialMedia/X.vue';
import ButtonTemplate from '@/components/widgets/button.vue';
import CheckMetrics from './CheckMetrics.vue';
import axios from 'axios';
import { supabase } from '@/lib/supabaseClient';
import { useSessionStore } from '@/stores/sessionStore';

import { config } from '@/config';
import StoryTellingConfirm from '@/components/screens/Posizionamento/StoryTellingConfirm.vue';
import ExperienceConfirm from '@/components/screens/Posizionamento/ExperienceConfirm.vue';
import CommunityConfirm from '@/components/screens/Posizionamento/CommunityConfirm.vue';
import FinalPage from '@/components/screens/Posizionamento/FinalPage.vue';
import StepIndicator from '@/components/widgets/StepIndicator.vue';

// import { push } from 'core-js/core/array';

export default {
  setup() {
    const sessionstore = useSessionStore();
    return { sessionstore };
  },

  name: 'SocialMediaChecker',
  components: {
    XIcon,
    PlusIcon,
    FacebookIcon,
    InstagramIcon,
    TiktokIcon,
    LinkedinIcon,
    TwitterIcon,
    ButtonTemplate,
    CheckMetrics,
    StoryTellingConfirm,
    ExperienceConfirm,
    CommunityConfirm,
    FinalPage,
    StepIndicator
  },
  data() {
    return {
      loading: true,
      showBox: true,
      // Using imported SVG components for icons
      socialChannels: [],
      newChannelText: '',
      selectedChannel: 'Instagram',
      availableChannels: ['Facebook', 'Instagram', 'Twitter', 'Tiktok', 'Linkedin'],
      showMetricsConstant: false,
      intervalId: null,
      ShowExperiencePage: false,
      ShowCommunityPage: false,
      ShowStorytellingPage: false,
      ShowFinalPage: false,
      CurrentIndex: 0,
      ShowNavigationMenu: false,
      storytellingScore: 0,
      experienceScore: 0,
      communityScore: 0,
    };
  },

  computed: {
    filteredSocialChannels() {
      return this.socialChannels.filter(channel => channel.text); // Return channels with non-null text
    }
  },

  mounted() {
    this.initiateProcessing();
  },

  methods: {
    getIconComponent(socialMediaName) {
      switch (socialMediaName) {
        case 'facebook': return FacebookIcon;
        case 'instagram': return InstagramIcon;
        case 'twitter': return TwitterIcon;
        case 'tiktok': return TiktokIcon;
        case 'linkedin': return LinkedinIcon;
        default: return null;
      }
    },

    async initiateProcessing() {
      console.log(
        '%cChecking social media channels for:\n' +
        `Company: ${this.sessionstore.company_name}\n` +
        `Website: ${this.sessionstore.website}`,
        'color: #4CAF50; font-weight: bold;'
      );

      try {
        // Query companies table for existing company data
        const { data: existingCompany } = await supabase
          .from('companies')
          .select('*')
          .eq('company_id', this.sessionstore.company_id)
          .maybeSingle();

        // If the company is found in the database and has social media data
        if (existingCompany && (existingCompany.instagram || existingCompany.facebook)) {
          console.log('Company social media found in DB: ', existingCompany);

          // Query sessions table for the most recent completed session
          const { data: sessionData } = await supabase
            .from('sessions')
            .select('*')
            .eq('company_id', this.sessionstore.company_id)
            .eq('status', 'completed')
            .order('date', { ascending: false })
            .limit(1)
            .maybeSingle();

          if (sessionData) {
            console.log('Most recent inactive session found:', sessionData);
            this.sessionstore.setMetrics(sessionData);
            this.displayResults(existingCompany);
          } else {
            console.log('No inactive session found for this company');
            const response = await this.sendPostRequest();
            this.sessionstore.setMetrics(response.data);
            this.displayResults(response.data);
          }
        } else {
          console.log('Company not found in DB. Sending POST request to /submit...', this.sessionstore.company_name, this.sessionstore.website);
          const response = await this.sendPostRequest();
          this.sessionstore.setMetrics(response.data);
          this.displayResults(response.data);
        }
      } catch (error) {
        console.error('Error processing company data:', error);
      }
    },

    async sendPostRequest() {

      const path = config.API_BASE_URL + '/submit'
      console.log('Sent POST requedst to /submit...');


      try {
        const response = await axios.post(path, {
          company_name: this.sessionstore.company_name,
          website: this.sessionstore.website
        });
        console.log('POST request response:', response.data);
        this.displayResults(response.data);
        return response;
      } catch (error) {
        console.error('Error sending POST request:', error);
        this.loading = false;
        this.displayResults({});
        return this.sessionstore.getMetrics;
      }
    },

    displayResults(data) {
      // predefined list of social media channels
      const socialMediaChannels = ['facebook', 'instagram', 'twitter', 'tiktok', 'linkedin'];
      // push social media data to socialChannels structure
      this.socialChannels = [];

      socialMediaChannels.forEach(channel => {
        const iconComponent = this.getIconComponent(channel);
        const channelData = data[channel] || null; // Use null if the channel data is missing

        this.socialChannels.push({
          key: channel,
          icon: iconComponent, // icon can still be null if getIconComponent doesn't find it
          text: channelData
        });
      });

      this.loading = false;
    },

    deleteChannel(index) {
      this.socialChannels.splice(index, 1);
    },

    async pushToDB() {
      // push socialChannels to the database
      console.log('Pushing social channels to the database...');
      const socialMediaData = {};
      this.socialChannels.forEach(channel => {
        socialMediaData[channel.key] = channel.text;
      });
      console.log('Social media data:', socialMediaData);
      const { error } = await supabase
        .from('companies')
        .update(socialMediaData)
        .eq('company_id', this.sessionstore.company_id);

      this.sessionstore.setSocial(socialMediaData);

      if (error) {
        console.error('Error updating social media channels:', error.message);
      } else {
        console.log('Social media channels updated successfully');
      }
    },

    addChannel() {
      if (this.newChannelText.trim() === '') return;

      const channelKey = this.selectedChannel ? this.selectedChannel.toLowerCase() : null;
      const allowedChannels = ['facebook', 'instagram', 'twitter', 'tiktok', 'linkedin'];

      // Check if the selected channel is valid and part of allowed channels
      if (!channelKey || !allowedChannels.includes(channelKey)) {
        console.error('Invalid or unsupported social media channel');
        return;
      }

      // Check if the channel already exists in socialChannels
      const existingChannel = this.socialChannels.find(channel => channel.key === channelKey);
      if (existingChannel) {
        // If the channel exists and its text is null, update the text
        if (!existingChannel.text || existingChannel.text === null) {
          existingChannel.text = this.newChannelText;
        } else {
          console.error('This channel already has a value.');
        }
      } else {
        // Add the new channel if it doesn't exist
        const iconComponent = this.getIconComponent(channelKey);
        this.socialChannels.push({
          key: channelKey,
          icon: iconComponent,
          text: this.newChannelText
        });
      }

      this.newChannelText = ''; // Reset the input field
    },



    showMetrics() {
      console.log('Showing metrics...\nCompany:', this.sessionstore.company_name, '\nWebsite:', this.sessionstore.website);
      this.pushToDB();
      this.showMetricsConstant = true;
      this.showBox = false;
    },
    showNavigationMenu() {
      this.ShowNavigationMenu = true
    },
    showFollowingPage() {
      if (this.CurrentIndex === 0) {
        this.ShowStorytellingPage = true;
        this.ShowCommunityPage = false;
        this.ShowExperiencePage = false;
        this.ShowFinalPage = false;
        this.CurrentIndex += 1;
        this.ShowNavigationMenu = false;
        this.showMetricsConstant = false;
      } else if (this.CurrentIndex === 1) {
        this.ShowStorytellingPage = false;
        this.ShowCommunityPage = false;
        this.ShowExperiencePage = true;
        this.ShowFinalPage = false;
        this.CurrentIndex += 1;
        this.ShowNavigationMenu = false;
      } else if (this.CurrentIndex === 2) {
        this.ShowStorytellingPage = false;
        this.ShowCommunityPage = true;
        this.ShowExperiencePage = false;
        this.ShowFinalPage = false;
        this.CurrentIndex += 1;
        this.ShowNavigationMenu = false;
      } else if (this.CurrentIndex === 3) {
        this.ShowStorytellingPage = false;
        this.ShowCommunityPage = false;
        this.ShowExperiencePage = false;
        this.ShowFinalPage = true;
        this.CurrentIndex += 1;
        this.ShowNavigationMenu = false;
      }
    },
    showPreviousPage() {
      if (this.CurrentIndex === 1) {
        this.ShowStorytellingPage = false;
        this.ShowCommunityPage = false;
        this.ShowExperiencePage = false;
        this.ShowFinalPage = false;
        this.CurrentIndex -= 1;
        this.ShowNavigationMenu = false;
      } else if (this.CurrentIndex === 2) {
        this.ShowStorytellingPage = true;
        this.ShowCommunityPage = false;
        this.ShowExperiencePage = false;
        this.ShowFinalPage = false;
        this.CurrentIndex -= 1;
        this.ShowNavigationMenu = false;
      } else if (this.CurrentIndex === 3) {
        this.ShowStorytellingPage = false;
        this.ShowCommunityPage = false;
        this.ShowExperiencePage = true;
        this.ShowFinalPage = false;
        this.CurrentIndex -= 1;
        this.ShowNavigationMenu = false;
      } else if (this.CurrentIndex === 4) {
        this.ShowStorytellingPage = false;
        this.ShowCommunityPage = true;
        this.ShowExperiencePage = false;
        this.ShowFinalPage = false;
        this.CurrentIndex -= 1;
        this.ShowNavigationMenu = false;
      }
    }
  },

};

</script>

<style lang="scss" scoped>
.page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  top: -50px;
}

.channel {
  display: flex;
  align-items: center;
  flex: 1;
}


.channel>* {
  margin-right: 10px;
}

.elimina-bottone {
  position: inherit;
}

.nome-canale {
  position: relative;
  flex: 1;
  margin-left: 25px;
}

.nome-canale:last-child {
  margin-bottom: 20px;
}

.sub-text {
  margin-top: 20px;
}

.add-channel {
  margin-top: 20px;
  display: flex;
  align-items: left;
}

.add-channel input {
  flex: 1;
  margin-right: 10px;
}

.add-channel select {
  margin-right: 10px;
}

.aggiungi-bottone {
  position: inherit;
}

.confirm-button {
  margin-top: 20px;
  --button-border-color: #1f6402;
  --button-text-color: #1f6402;
  --button-background-color: #fff;
  --button-background-hover-color: #1b560240;
}

.steps {
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.matrix-page {
  width: 100vw;
  height: 100vh;
  margin-top: 50px;
  background-color: #fff;
}
</style>
