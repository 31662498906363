<template>
    <div class="main">
      <div class="left-section">
        <div class="content" style="align-items: flex-end;">
            <div class="sub-window" style="background-color: #fff; border-top-left-radius: 15px;  border-bottom-left-radius: 15px;">
                <div class="logo-text">BBW LOGO</div>
                <div class="login-text">Inizia con noi!</div>
                <div class="login">
                    <div class="google-login">
                    <Button class="google-button" @click="GoogleLogin">
                        <GoogleIcon class="google-icon"> </GoogleIcon>
                        Iscriviti con Google
                    </Button>
                    </div>
                    <div class="email-login">
                      <span class="oppure">
                        <span class="line-before"></span>
                        Oppure iscriviti con la tua mail
                        <span class="line-after"></span>
                      </span>
                    <input class="form-elements" type="text" id="Mail" v-model="Mail" placeholder="Inserisci la tua mail" required/>
                    <input class="form-elements" type="password" id="Password" v-model="Password" placeholder="Inserisci la password" required/>
                    <div class="sub-section">
                        <label for="remember-me" class="checkbox-label">
                            <input type="checkbox" id="remember-me" class="box-tick"> Ricordati di me
                        </label>
                    </div>
                    <Button class="login-button" @click="register">
                      Registrati
                      </Button>
                      <span class="straight-line"> </span>
                    </div>
          
                    <div class="subscribe">Hai giá un account? <router-link class="link-sub" to="/login">Accedi!</router-link></div>
                </div>
            </div>
        </div>
      </div>
      <div class="right-section">
        <div class="content" style="align-items: flex-start;">
            <div class="sub-window" style="background-color: #f8f8f8; border-top-right-radius: 15px;  border-bottom-right-radius: 15px;">
                <div class="welcome-image">
                    <!-- Inserire immagine di benvenuto quando ci sara' -->
                </div>
                <div class="welcome-text">
                  <span>Inizia il tuo</span>
                </div>
                <div class="welcome-text">
                  <span>Viaggio</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import GoogleIcon from '@/assets/Icons/SocialMedia/Google.vue';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/stores/authStore';
  import { supabase } from '@/lib/supabaseClient';
  
  export default {
    name: 'MainSignIn',
    components: {
      GoogleIcon
    },
    setup() {
      const Mail = ref("");
      const Password = ref("");
      const router = useRouter();
      const auth = useAuthStore();
  
      const register = async () => {
        await auth.register(Mail.value, Password.value);
        if (!auth.error) {
          console.log("Utente Registrato con successo!");
          
          const user = auth.user;
          if (user) {
            await addUserToSupabase(user.uid, Mail.value);
          }
  
          router.push('/');
        } else {
          console.log(auth.error);
          alert(auth.error);
        }
      };
  
      const GoogleLogin = async () => {
        await auth.googleLogin();
        if (!auth.error) {
          console.log("Utente Registrato con successo con Google!");
  
          const user = auth.user;
          if (user) {
            await addUserToSupabase(user.uid, user.email);
          }
  
          router.push('/');
        } else {
          console.log(auth.error);
          alert(auth.error);
        }
      };
  
      const addUserToSupabase = async (userId, email) => {
        try {
          const { data, error } = await supabase
            .from('users')
            .insert([{ user_id: userId, email: email, first_name: 'Studente', last_name: 'Studente' }]);
  
          if (error) throw error;
          console.log("User added to Supabase:", data);
  
          // Insert into user_account
          const { data: accountData, error: accountError } = await supabase
            .from('user_account')
            .insert([{ 
              user_id: userId, 
              type_of_user: 'CONSULTANT', 
              type_of_plan: 'STANDARD', 
              created_at: new Date().toISOString()
            }]);
  
          if (accountError) throw accountError;
          console.log("User account created:", accountData);
        } catch (err) {
          console.error("Error adding user to Supabase:", err.message);
        }
      };
  
      return {
        Mail,
        Password,
        register,
        GoogleLogin,
        auth,
      };
    }
  };
  </script>
  

<style lang="scss" scoped>
  html, body {
  margin: 0;
}

.main {
  display: flex;
  width: 100%;
  height: 100vh; 
  background: linear-gradient(45deg, #00b09b, #96c93d 30%, #00b07e); 
}
  .left-section, .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .sub-window{
    height: 80vh; 
    width: 80%;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.1), 0 4px 10px -1px rgba(0, 0, 0, 0.06);
  }
  
  .logo-text {
    text-decoration: solid;
    font-size: 30px;
    font-weight:900;
    margin-top: 40px
  }
  
  .email-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    .form-elements {
      margin-bottom: 7px;
      padding: 3px 60px;
    }
  }
  
  .sub-section {
    display: flex;
    justify-content:space-evenly;
    width: 50%;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 10px;
    
  }
.checkbox-label {
  display: flex; 
  align-items: center; 
  cursor: pointer;
}
.box-tick{
    margin-right: 5px;
}
  .keep-info, .forgot {
    width: 50%;     //da cambiare
    text-align: center;
  }
  
  .subscribe {
    margin-top: 15px;
    font-size: 12px;
  }

  .login-text{
    font-size: 30px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px
  }
  .google-button{
    padding: 5px 60px;
    cursor: pointer;
    background-color: white; 
    border: 1px transparent rgba(236, 236, 236, 0.632);
    border-radius: 3px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);

  }
  .google-icon{
    position: relative;
    left: -50px; //da cambiare e rendere automatico
  }
  .oppure{
    color: rgb(194, 194, 194);
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 15px;
    position: relative;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 200%;
  }

.oppure .line-before,
.oppure .line-after {
  background-color: rgb(234, 232, 232); 
  height: 1px; 
}

.oppure .line-before {
  width: 10%; 
  margin-right: 7px; 
}

.oppure .line-after {
  width: 10%; 
  margin-left: 7px; 
}
  .login-button{
    padding: 5px 110px;  //qua la dimensione E raddoppiata (?)
    cursor: pointer;
    border-radius: 10px;
    background-color:#2e4eed;
    color: #fff;
    margin-top: 10px;
    border: none;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);;
  }
  .straight-line{
    margin-top:15px;
    width: 80%;
    background-color: rgb(234, 232, 232); 
    height: 1px; 
  }

.link-sub{
color: #2e4eed;
}

.welcome-text{
  display: flex; 
  position: relative;
  font-size: 50px;
  color: #000;
  font-weight: 900;
  align-items: flex-start;
  margin-left: 5%;
  margin-top: 60%;
}

.welcome-text:last-child{
  margin-top: 0%;
  color: #00b07e;
}
  </style>
  